<ng-container *ngIf="jobApplication$ | async as jobApplication">
  <!-- with travel cost -->
  <ng-container *ngIf="user$ | async as userDetail">
    <main>
      <form
        [ngStyle]="{'max-height': jobApplication?.jobInvoiceItem?.status === 'waiting' ? 'calc(100vh - 320px)' : 'calc(100vh - 100px)'}"
        #formContainer
        class="mobile-claim-travel-expenses"
        [formGroup]="form"
        (submit)="submit()">
        <p
          style="display: flex; gap: 10px"
          *ngIf="jobApplication?.jobInvoiceItem?.status === 'waiting' && step === 2"
          (click)="backStep()">
          <img src="/assets/img/mobile/arrow-back.svg" />
          {{'Back' | translate}}
        </p>
        <h5 *ngIf="jobApplication?.jobInvoiceItem?.status === 'waiting'">
          <span *ngIf="step === 1">{{'Check the info and follow the steps' | translate}}</span>
          <span *ngIf="step === 2">{{'Create your invoice' | translate}}</span>
        </h5>
        <ng-container *ngIf="jobApplication?.jobInvoiceItem?.status === 'decline'">
          <h5>
            <span>{{'You disagree with the info' | translate}}</span>
          </h5>
          <div *ngIf="jobApplication?.jobInvoiceItem?.reasonDecline" class="disagree-card">
            <p>Your message :</p>
            {{jobApplication?.jobInvoiceItem?.reasonDecline}}
          </div>
        </ng-container>
        <div
          [ngStyle]="{'opacity': jobApplication?.jobInvoiceItem?.status === 'decline' ? '0.5' : '1' }"
          *ngIf="jobApplication?.jobInvoiceItem !== null && jobApplication?.jobInvoiceItem !== undefined; else waitingCompleteJob"
          class="job-detail-card job-fee">
          <p>{{jobApplication.job?.title}}</p>
          <ol>
            <li>{{'Fee' | translate}}</li>
            <li>:</li>
            <li>
              €{{jobApplication?.jobInvoiceItem.fee}}
              <span
                style="color: red"
                *ngIf="(jobApplication?.jobInvoiceItem.fee !==
                  (jobApplication?.job?.rate))">
                ({{'Changed' | translate}})
              </span>
            </li>
          </ol>
          <ol>
            <li>{{'Buy-out' | translate}}</li>
            <li>:</li>
            <li>
              €{{jobApplication?.jobInvoiceItem.buyOut}}
              <span
                style="color: red"
                *ngIf="(jobApplication?.jobInvoiceItem.buyOut !==
                  (jobApplication?.job?.extraFees === null ? 0 : jobApplication?.job?.extraFees))">
                ({{'Changed' | translate}})
              </span>
            </li>
          </ol>
          <ol>
            <li>{{'Comission' | translate}} ({{userDetail.membership.commission}}%)</li>
            <li>:</li>
            <li>
              €{{getComission((jobApplication?.jobInvoiceItem.fee+jobApplication?.jobInvoiceItem.buyOut),userDetail.membership.commission)}}
            </li>
          </ol>
          <ol class="border-top">
            <li>{{'Travel cost' | translate}}</li>
            <li>:</li>
            <li *ngIf="!jobApplication?.jobInvoiceItem.tc; else enableTravelCost">{{'Disabled' | translate}}</li>
            <ng-template #enableTravelCost>
              <li>
                {{getTravelCost() | translate}}
                <span
                  style="color: red"
                  *ngIf="(jobApplication?.job?.travelingCost === null && jobApplication?.jobInvoiceItem.tc) ||
                    (jobApplication?.job?.travelingCost !== null && !jobApplication?.jobInvoiceItem.tc) ||
                    (jobApplication?.job?.travelingCost === null ? 0 : jobApplication?.job?.travelingCost !== jobApplication?.jobInvoiceItem.tcAmount)">
                  ({{'Changed' | translate}})
                </span>
                <span *ngIf="jobApplication?.status !== JobApplicationStatus.confirmed" translate>
                  {{jobApplication?.status === JobApplicationStatus.travelCostPending ? '(Awaits Approval)' :
                  jobApplication?.status === JobApplicationStatus.travelCostApproved ? '(Approved)' :
                  jobApplication?.status === JobApplicationStatus.travelCostRejected ? '(Rejected)' : ''}}
                </span>
                <span *ngIf="jobApplication?.status === JobApplicationStatus.confirmed && step == 2" translate>
                  (Awaits Approval)
                </span>
              </li>
            </ng-template>
          </ol>
          <ol>
            <li>{{'Parking cost' | translate}}</li>
            <li>:</li>
            <li *ngIf="!jobApplication?.jobInvoiceItem.pc; else enableParkingCost">{{'Disabled' | translate}}</li>
            <ng-template #enableParkingCost>
              <li>
                {{getParkingCost() | translate}}
                <span
                  style="color: red"
                  *ngIf="(jobApplication?.job?.parkingCost && !jobApplication?.jobInvoiceItem.pc) ||
            (!jobApplication?.job?.parkingCost && jobApplication?.jobInvoiceItem.pc) ||
            (jobApplication?.job?.maxParkingCost === null ? 0 : jobApplication?.job?.maxParkingCost  !== jobApplication?.jobInvoiceItem.pcMaxAmount)">
                  {{'(Changed)' | translate}}
                </span>
                <span *ngIf="jobApplication?.status !== JobApplicationStatus.confirmed" translate>
                  {{jobApplication?.status === JobApplicationStatus.travelCostPending ? '(Awaits Approval)' :
                  jobApplication?.status === JobApplicationStatus.travelCostApproved ? '(Approved)' :
                  jobApplication?.status === JobApplicationStatus.travelCostRejected ? '(Rejected)' : ''}}
                </span>
                <span *ngIf="jobApplication?.status === JobApplicationStatus.confirmed && step == 2" translate>
                  (Awaits Approval)
                </span>
              </li>
            </ng-template>
          </ol>
          <ol class="border-top">
            <li>{{'Fee excl. VAT' | translate}}</li>
            <li>:</li>
            <li>
              €{{totalFeeExcl((jobApplication?.jobInvoiceItem.fee+jobApplication?.jobInvoiceItem.buyOut),userDetail.membership.commission)}}
            </li>
          </ol>
          <ol>
            <li>{{'Optional 21% VAT' | translate}}</li>
            <li>:</li>
            <li>
              €{{totalVAT((jobApplication?.jobInvoiceItem.fee+jobApplication?.jobInvoiceItem.buyOut),userDetail.membership.commission)}}
            </li>
          </ol>
          <ol class="border-bottom">
            <li>{{'Fee incl. VAT' | translate}}</li>
            <li>:</li>
            <li>
              €{{totalFeeIncl((jobApplication?.jobInvoiceItem.fee+jobApplication?.jobInvoiceItem.buyOut),userDetail.membership.commission)}}
            </li>
          </ol>
          <!-- <ng-container *ngIf="jobApplication?.jobInvoiceItem?.status === 'waiting' && step === 1">
            <button (click)="incorrectFee()" type="button" class="btn-incorrect-fee">Info is not correct</button>
          </ng-container> -->
          <div *ngIf="jobApplication?.jobInvoiceItem?.status === 'waiting' && step === 2">
            <p style="margin: 20px 0 10px 0">
              {{'Optional: Costum Invoice/Kwitantie number' | translate}} {{jobApplication?.jobInvoiceItem?.status ===
              'waiting'}}
            </p>
            <div>
              <div class="input-group rounded-input">
                <input type="text" class="form-control w-100" formControlName="ownNumber" />
              </div>
            </div>
          </div>
        </div>

        <ng-template #waitingCompleteJob>waiting caster to complete job</ng-template>

        <ng-container
          *ngIf="(jobApplication?.jobInvoiceItem !== null && jobApplication?.jobInvoiceItem !== undefined) && (!jobApplication?.withoutTc && step === 1 && jobApplication?.jobInvoiceItem?.status === 'waiting' )">
          <!-- <p class="error" *ngIf="jobApplication.status === JobApplicationStatus.travelCostRejected">
              <span translate>Rejection comment from caster:</span>
              {{ jobApplication.costRejectionReason }}
            </p> -->

          <!-- travel expenses -->
          <ng-container formArrayName="travelExpenses">
            <ng-container *ngFor="let travelExpense of travelExpenses.controls; let i = index">
              <div class="tc-form-mobile with-card">
                <ng-container [formGroupName]="i">
                  <app-mobile-claim-travel-expenses-row
                    [maxInvoicePCAmount]="jobApplication?.jobInvoiceItem?.pcMaxAmount>0 ?jobApplication?.jobInvoiceItem?.pcMaxAmount: jobApplication?.job?.maxParkingCost"
                    [maxInvoiceTCAmount]="jobApplication?.jobInvoiceItem?.tcMaxAmount> 0 ? jobApplication?.jobInvoiceItem?.tcMaxAmount : jobApplication?.job?.maxTravelingCost"
                    [travelingCost]="jobApplication?.jobInvoiceItem?.tcAmount>0 ?jobApplication?.jobInvoiceItem?.tcAmount:jobApplication?.job?.
                      travelingCost"
                    [jobApplication]="jobApplication"
                    [formGroup]="travelExpense | as: FormGroup"
                    [disableTravelCost]="jobApplication?.jobInvoiceItem?.tc"
                    [disableParkingCost]="jobApplication?.jobInvoiceItem?.pc"
                    (remove)="removeTravelExpense($event)"
                    (copyForm)="copyTravelExpense($event)"
                    [addBtn]="showAddButton(travelExpense.value.type, (i + 1))"
                    [removeBtn]="showButtonDelete(travelExpense.value.id)"></app-mobile-claim-travel-expenses-row>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <p style="color: red" translate *ngIf="!jobApplication?.jobInvoiceItem?.pc" translate>
            Parking costs are not reimbursed
          </p>
          <p style="color: red" translate *ngIf="!jobApplication?.jobInvoiceItem?.tc">Travel cost is not included</p>
          <div class="d-flex">
            <div class="mr-2">
              <img src="/assets/img/light.svg" style="width: 18px; height: 18px; position: relative; bottom: 2px" />
            </div>
            <p translate>
              The price will be automatically calculated when kilometers are filled in.
              <br />
              When the field becomes red, it means it has exceeded the max amount allowed for this job.
            </p>
          </div>
          <!-- receipts -->
          <div class="tc-form-mobile">
            <div class="row" style="gap: 30px 0">
              <div class="col-lg-2 col-md-3 col-xs-2 col-6">
                <div class="card-receipt" (click)="chooseImage()">
                  <div class="wrap">
                    <span
                      *ngIf="isBusyUpload"
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"></span>
                    <ng-container *ngIf="!isBusyUpload">
                      <div>
                        <img src="/assets/img/upload-receipt.svg" class="mb-2" />
                        <p>Upload receipt</p>
                      </div>
                      <input
                        #picture
                        type="file"
                        class="d-none"
                        (input)="uploadReceipt($event.target.files)"
                        accept="image/png, image/jpg, image/jpeg" />
                    </ng-container>
                  </div>
                </div>
              </div>
              <ng-container formArrayName="travelReceipts">
                <ng-container *ngFor="let receipt of travelReceipts.controls; let i = index">
                  <div class="col-lg-2 col-md-3 col-xs-2 col-6" [formGroupName]="i">
                    <div class="card-receipt" [ngStyle]="{'background-image': 'url(' + receipt.value.url + ')'}">
                      <div class="wrap">
                        <span
                          *ngIf="isBusyUpload"
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>
                        <img
                          *ngIf="!isBusyUpload"
                          class="delete-icon"
                          src="/assets/img/trash2.svg"
                          (click)="removeReceipt(i)" />
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <div class="col-lg-2 col-md-3 col-xs-2 col-6" *ngIf="isBusyUpload">
                <app-spinner
                  class="align-self-center justify-self-center"
                  style="width: 70px; margin-top: 30px"></app-spinner>
              </div>
            </div>
            <div class="d-flex mt-3" style="border-top: 1px solid white; padding-top: 20px">
              <div class="mr-2">
                <img src="/assets/img/light.svg" style="width: 18px; height: 18px; position: relative; bottom: 2px" />
              </div>
              <p>The caster will approve/reject travel and/or parking cost</p>
            </div>
          </div>
        </ng-container>
        <ng-container
          *ngIf="(jobApplication?.jobInvoiceItem !== null && jobApplication?.jobInvoiceItem !== undefined) && (jobApplication?.withoutTc || step === 2)">
          <h5 *ngIf="jobApplication?.jobInvoiceItem?.status === 'waiting'" translate>
            Is your information still correct?
          </h5>
          <br />
          <div class="job-fee no-margin">
            <ol>
              <li>{{'Company Name' | translate}}</li>
              <li>:</li>
              <li>{{userDetail.companyName ? companyName : '-'}}</li>
            </ol>
            <ol>
              <li>{{'Name' | translate}}</li>
              <li>:</li>
              <li>{{userDetail.firstName}} {{userDetail.lastName}}</li>
            </ol>
            <ol>
              <li>{{'Street name + housenumber' | translate}}</li>
              <li>:</li>
              <li>{{userDetail.street}} {{userDetail.houseNumber}}</li>
            </ol>
            <ol>
              <li>{{'Postal code + Location' | translate}}</li>
              <li>:</li>
              <li>{{userDetail.postalCode}} {{userDetail.country.name}}</li>
            </ol>
            <ol class="border-top">
              <li>{{'Bank number' | translate}}</li>
              <li>:</li>
              <li>{{form.get('accountNumber.bankNumber')?.value?form.get('accountNumber.bankNumber')?.value:'-'}}</li>
            </ol>
            <ol>
              <li>{{'Name registered with bank' | translate}}</li>
              <li>:</li>
              <li>{{form.get('accountNumber.accountName')?.value?form.get('accountNumber.accountName')?.value:'-'}}</li>
            </ol>
            <ol>
              <li>{{'COC-number' | translate}}</li>
              <li>:</li>
              <li>{{form.get('accountNumber.coc')?.value?form.get('accountNumber.coc')?.value:'-'}}</li>
            </ol>
            <ol>
              <li>{{'VAT-Number' | translate}}</li>
              <li>:</li>
              <li>{{form.get('accountNumber.vat')?.value?form.get('accountNumber.vat')?.value:'-'}}</li>
            </ol>
            <ol>
              <li>{{'KOR' | translate}}</li>
              <li>:</li>
              <li>{{form.get('accountNumber.kor')?.value==true?'Yes':'No'}}</li>
            </ol>
            <ol>
              <li>{{'Phone number' | translate}}</li>
              <li>:</li>
              <li>+{{userDetail.country.callingCode}} {{userDetail.phonenumber}}</li>
            </ol>
            <ol class="border-bottom">
              <li>{{'Email addres' | translate}}</li>
              <li>:</li>
              <li>{{userDetail.email}}</li>
            </ol>
            <div class="d-flex mt-3">
              <div class="mr-2">
                <img src="/assets/img/light.svg" style="width: 18px; height: 18px; position: relative; top: 3px" />
              </div>
              <p>When COC/VAT-number is not filled in, you can only send a Kwitantie, not an Invoice</p>
            </div>
            <button
              *ngIf="jobApplication?.jobInvoiceItem?.status === 'waiting'"
              (click)="showFormAccountNumber()"
              type="button"
              class="btn-account-number">
              <img src="/assets/img/pen.svg" alt="" />
              I want to change my info
            </button>
          </div>
        </ng-container>
      </form>
      <div *ngIf="jobApplication?.jobInvoiceItem?.status === 'waiting'" class="travel-cost-action">
        <ng-container
          *ngIf="(jobApplication?.jobInvoiceItem !== null && jobApplication?.jobInvoiceItem !== undefined) && (!jobApplication?.withoutTc && step ===1)">
          <button type="button" (click)="nextStep()" translate>Continue</button>
          <button
            *ngIf="jobApplication?.jobInvoiceItem?.pc || jobApplication?.jobInvoiceItem?.tc"
            (click)="openNoTracelcostAgreement()"
            type="button"
            translate>
            No Travel Cost
          </button>
        </ng-container>
        <ng-container
          *ngIf="(jobApplication?.jobInvoiceItem !== null && jobApplication?.jobInvoiceItem !== undefined) && (jobApplication?.withoutTc || step === 2)">
          <button type="button" (click)="submitConfirmation()" translate>Sent invoice / kwitantie via Casterbee</button>
          <button (click)="submitZeroTravelCostConfirmationModal(true)" type="button" translate>
            I send my Invoice by myself to Casterbee
          </button>
        </ng-container>
      </div>

      <app-mobile-claim-travel-expenses-account-number-modal
        (saveAccountNumber)="saveAccountNumber($event)"
        #accountNumberModal></app-mobile-claim-travel-expenses-account-number-modal>

      <app-mobile-claim-travel-expenses-incorrect-fee
        #incorrectFeeModal></app-mobile-claim-travel-expenses-incorrect-fee>
      <app-mobile-success-modal height="unset!important" maxHeight="unset!important" #successSubmitModal>
        <app-mobile-success [message]="'Your invoice is being processed.'"></app-mobile-success>
        <button class="close-dialog-button" (click)="reloadPage()">Close</button>
      </app-mobile-success-modal>
      <app-mobile-success-modal height="unset!important" maxHeight="unset!important" #successSubmitAlternateModal>
        <app-mobile-success [message]="'Thank you for letting us know'"></app-mobile-success>
        <button class="close-dialog-button" (click)="reloadPage()">Close</button>
      </app-mobile-success-modal>
      <app-mobile-success-modal height="unset!important" maxHeight="unset!important" #nextStepConfirmationModal>
        <div class="agreement-message">
          <p translate>Your travel cost will be sent after you have finished the next invoicing / kwitantie step</p>
          <button class="purple" (click)="confirmAgreement()" translate>Understood</button>
        </div>
      </app-mobile-success-modal>
      <app-mobile-success-modal height="unset!important" maxHeight="unset!important" #zeroTravelCost>
        <div class="agreement-message">
          <p>Your travel costs have been recorded as zero</p>
          <button class="black" (click)="confirmAgreement()">Close</button>
        </div>
      </app-mobile-success-modal>
      <app-mobile-success-modal height="unset!important" maxHeight="unset!important" #submitZeroTravelCostConfirmation>
        <div class="agreement-message">
          <p translate>Are you sure you want to send the invoice by yourself to Casterbee?</p>
          <div>
            <button class="purple" (click)="submit('disable')">Yes</button>
            <button class="black" (click)="submitZeroTravelCostConfirmationModal(false)">Cancel</button>
          </div>
        </div>
      </app-mobile-success-modal>
      <app-mobile-success-modal height="unset!important" maxHeight="unset!important" #zeroTravelCostConfirmation>
        <div class="agreement-message">
          <p>Are you sure there were no travel cost?</p>
          <div>
            <button class="purple" (click)="confirmNoTravelcost(true)">Yes</button>
            <button class="black" (click)="cancelNoTravelCost()">Cancel</button>
          </div>
        </div>
      </app-mobile-success-modal>
      <app-mobile-success-modal height="unset!important" maxHeight="unset!important" #submitConfirmationModal>
        <div class="agreement-message">
          <div>
            <h3>Are you sure you want to sent it?</h3>
            <br />
            <p class="small">
              Your invoice/kwitantie will be automatically sent to Casterbee with you in .CC after your possible travel
              cost are approved.
            </p>
            <p class="small">Payments are done within day 30-40 after receiving invoice/kwitantie.</p>
          </div>
          <div>
            <button class="purple" (click)="submit()">Yes</button>
            <button class="black" (click)="cancelSubmitConfirmation()">Cancel</button>
          </div>
        </div>
      </app-mobile-success-modal>
    </main>
  </ng-container>
</ng-container>
<div *ngIf="isBusy" id="loading-x" class="loading-screen talent-horizontal">
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
</div>
