<ng-container *ngIf="!!applicant.user">
  <app-image
    [image]="applicant.user?.photo?.thumbUrl"
    [ngStyle]="{ 'height.px': 330, 'background-color': '#414349' }"
    class="cover d-block rounded-sm initial"></app-image>

  <div class="position-absolute card-content w-100 h-100">
    <div class="d-flex w-100 flex-column h-100 overflow-hidden animate-content">
      <div class="d-flex flex-grow-1 header-content w-100"></div>
      <div class="body-content d-flex flex-column align-items-center w-100" [ngClass]="getBodyContentClass()">
        <div class="d-flex flex-row">
          <h5 class="hover-effect" (click)="goToApplicantProfile()">{{ applicant.user?.profileName }}</h5>
          <app-icon
            *ngIf="applicant.user?.hasBadge"
            icon="/assets/img/memberships-badge.svg"
            class="size-5 ml-1"></app-icon>
        </div>
        <div translate>{{ "AgeInCard" | translate: { age: applicant.user?.age } }}</div>
        <div class="mt-1" *ngIf="applicant.user.averageReview > 0">
          <app-rating [average]="applicant.user.averageReview" [count]="applicant.user.totalReviews"></app-rating>
        </div>
        <div class="mt-2" translate>
          {{ "LocationInCard" | translate: { city: applicant.user?.city, country: applicant.user?.country.isoCode } }}
        </div>
        <div>
          <span translate>Native Language</span>
          : {{ nativeLanguage?.translateKey | translate }}
        </div>
        <div class="mt-2 d-flex flex-row w-100 justify-content-center" *ngIf="showVideoButton()">
          <div class="flex-grow-1 justify-content-center d-flex" (click)="viewTalentVideo(applicant)">
            <span class="hover-effect">
              <fa-icon [styles]="{ color: '#FFFFFF', 'margin-right': '5px' }" [icon]="playCircleIcon"></fa-icon>
              <span translate>Video</span>
            </span>
          </div>
        </div>
        <div class="mt-2 d-flex flex-row w-100 justify-content-center" *ngIf="showRequestVideoButton()">
          <div class="flex-grow-1 justify-content-center d-flex" (click)="requestTalentVideo(applicant)">
            <span class="hover-effect">
              <fa-icon [styles]="{ color: '#FFFFFF', 'margin-right': '5px' }" [icon]="playCircleIcon"></fa-icon>
              <span translate>Request video</span>
            </span>
          </div>
        </div>
        <div class="mt-2 d-flex flex-row w-100 justify-content-center" *ngIf="showVideoRequestedInfo()">
          <div class="flex-grow-1 justify-content-center d-flex">
            <span class="hover-effect">
              <fa-icon [styles]="{ color: '#FFFFFF', 'margin-right': '5px' }" [icon]="playCircleIcon"></fa-icon>
              <span translate>Video requested</span>
            </span>
          </div>
        </div>
        <div class="mt-2 d-flex flex-row w-100 justify-content-center" *ngIf="applicant?.notes">
          <div class="flex-grow-1 justify-content-center d-flex" (click)="viewTalentNote(applicant)">
            <span class="hover-effect">
              <fa-icon [styles]="{ color: '#FFFFFF', 'margin-right': '5px' }" [icon]="noteIcon"></fa-icon>
              <span translate>Read note</span>
            </span>
          </div>
        </div>
        <div class="mt-2 button-container">
          <app-solid-button (click)="openChatModal()" buttonStyle="transparent-white">
            <span translate>Message</span>
          </app-solid-button>
        </div>
        <!-- <div class="mt-1 button-container" *ngIf="showSendTravelCostsRequest()">
          <app-solid-button
            buttonStyle="artyclick-purple"
            (click)="requestTravelCosts(applicant)"
            [isBusy]="isBusyButton == 'requestTravelCosts'">
            <span translate>Request travel costs</span>
          </app-solid-button>
        </div> -->
        <div class="mt-1 button-container" *ngIf="showRequestedTravelCosts()">
          <app-solid-button buttonStyle="no-border-white">
            <span translate>Travel costs requested</span>
          </app-solid-button>
        </div>
        <div class="mt-1 button-container" *ngIf="applicant.status === JobApplicationStatus.travelCostRejected">
          <app-solid-button buttonStyle="no-border-white">
            <span class="smaller-text" translate>Travel costs rejected</span>
          </app-solid-button>
        </div>
        <div class="mt-1 button-container" *ngIf="applicant.status === JobApplicationStatus.travelCostApproved">
          <app-solid-button buttonStyle="no-border-white">
            <span class="smaller-text" translate>Travel costs approved</span>
          </app-solid-button>
        </div>

        <div
          class="mt-1 button-container"
          *ngIf="showRequestToBookWithPaymentButton() && !showSendTravelCostsRequest()">
          <app-solid-button
            buttonStyle="artyclick-purple"
            (click)="requestToBookAndCreatePayment(applicant, job)"
            [isBusy]="isBusyButton == 'requestToBookAndCreatePayment'">
            <span translate>Request to book</span>
          </app-solid-button>
        </div>
        <div
          class="mt-1 button-container"
          *ngIf="showRequestToBookBypassPaymentButton() && !showSendTravelCostsRequest()">
          <app-solid-button
            buttonStyle="artyclick-purple"
            (click)="requestToBook(applicant, job)"
            [isBusy]="isBusyButton == 'requestToBook'">
            <span translate>Request to book</span>
          </app-solid-button>
        </div>

        <div class="mt-1 button-container" *ngIf="showCreatePaymentButton()">
          <app-solid-button
            buttonStyle="artyclick-purple"
            (click)="createPayment(applicant)"
            [isBusy]="isBusyButton == 'createPayment'">
            <span translate>Complete Payment</span>
          </app-solid-button>
        </div>

        <div class="mt-1 button-container" *ngIf="showReleasePaymentButton()">
          <app-solid-button
            buttonStyle="artyclick-purple"
            [isBusy]="isBusyButton == 'releasePayment'"
            (click)="releasePayment(applicant, 0)">
            <span translate>Release payment</span>
          </app-solid-button>
        </div>

        <div class="mt-1 button-container" *ngIf="showRequestRefundButton()">
          <app-solid-button
            buttonStyle="artyclick-purple"
            (click)="requestRefundPayment(applicant)"
            [isBusy]="isBusyButton == 'requestRefundPayment'">
            <span translate>Request refund</span>
          </app-solid-button>
        </div>
        <div class="mt-1 button-container" *ngIf="showRejectApplicantButton()">
          <app-solid-button buttonStyle="dark-gray" [isBusy]="isBusyButton == 'reject'" (click)="reject(applicant)">
            <span translate>Reject</span>
          </app-solid-button>
        </div>
        <div class="mt-1 button-container" *ngIf="showCancelBookingButton()">
          <app-solid-button
            buttonStyle="black"
            (click)="cancelBooking(applicant)"
            [isBusy]="isBusyButton == 'cancelBooking'">
            <span translate>Cancel booking</span>
          </app-solid-button>
        </div>
        <div class="mt-1 button-container" *ngIf="showCancelRequestBookingButton()">
          <app-solid-button
            buttonStyle="black"
            (click)="cancelBooking(applicant)"
            [isBusy]="isBusyButton == 'cancelBooking'">
            <span class="smaller-text" translate>Cancel booking request</span>
          </app-solid-button>
        </div>
        <div class="mt-1 button-container" *ngIf="applicant.status === JobApplicationStatus.travelCostPending">
          <app-solid-button
            buttonStyle="artyclick-purple"
            (click)="travelCosts(applicant)"
            [isBusy]="isBusyButton == 'travelCostPending'">
            <span class="smaller-text" translate>See travel cost</span>
          </app-solid-button>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column position-absolute checkbox w-100" *ngIf="!job.completed">
      <label class="d-flex align-items-center flex-row" (click)="onSelect($event)">
        <app-checkbox [checked]="isSelected"></app-checkbox>
      </label>
    </div>
  </div>
</ng-container>
