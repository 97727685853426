import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { JobApplication, Job, Language } from '@core/models';
import { TranslateService } from '@ngx-translate/core';
import { faFileLines, faPlayCircle, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { RequestRefundJobApplicationComponent } from '@shared/components/job-applications/request-refund/request-refund.component';
import { RequestToBookModalComponent } from '@src/visitor/modals/components/request-to-book-modal/request-to-book-modal.component';
import { BulkRejectComponent } from '@src/account/job-applications/modals/bulk-reject/bulk-reject.component';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';
import { MessagingModalComponent } from '../../../messaging/components/messaging-modal/messaging-modal.component';
import { CasterCancelBookingComponent } from '@src/account/jobs/modals/caster-cancel-booking/caster-cancel-booking.component';

import { Navigate } from '@ngxs/router-plugin';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { CreatePayment, InitiateChat, RequestTalentVideoForJobApplication, UpdateEscrow } from '@core/states';
import { JobApplicationStatus } from '@core/interfaces';
import { JobApplicationsService } from '@core/services';
import * as moment from 'moment';
import { MobileJobNoteDetailComponent } from '@shared/mobile-components/mobile-job-note-detail/mobile-job-note-detail.component';

@Component({
  selector: 'app-applicant-card',
  templateUrl: './applicant-card.component.html',
  styleUrls: ['./applicant-card.component.scss'],
})
export class ApplicantCardComponent {
  @Input()
  public applicant: JobApplication;

  @Input()
  public job: Job;

  @Input()
  @HostBinding('class.is-dim')
  public dim = false;

  @Output()
  public handleSelect = new EventEmitter<any>();

  @Input()
  public isSelected = false;

  public fileLinesIcon = faFileLines;
  public playCircleIcon = faPlayCircle;
  public noteIcon = faStickyNote;

  public isBusyButton: string;
  public isBusyId: string;
  public requestedRefund = false;
  public bodyContentClass = 'body-content-2btn';

  protected readonly JobApplicationStatus = JobApplicationStatus;

  public constructor(
    public translate: TranslateService,
    public jobApplicationService: JobApplicationsService,
    private dialog: MatDialog,
    private store: Store,
    private route: ActivatedRoute,
  ) {}

  public goToApplicantProfile(): void {
    this.store.dispatch(
      new Navigate(['profile', this.applicant.user.id, this.applicant.id], this.route.snapshot.queryParams, {
        relativeTo: this.route,
      }),
    );
  }

  public onSelect(ev: Event): void {
    ev.preventDefault();
    this.isSelected = !this.isSelected;
    this.handleSelect.emit({ checked: this.isSelected, id: this.applicant.id });
  }

  public requestTalentVideo(jobApplication: JobApplication): void {
    this.store.dispatch(new RequestTalentVideoForJobApplication(jobApplication.id));
  }

  public async viewTalentVideo(jobApplication: JobApplication): Promise<void> {
    this.dialog.open(MediaItemModalComponent, {
      data: {
        media: jobApplication.talentVideo,
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public async viewTalentNote(jobApplication: JobApplication): Promise<void> {
    this.dialog.open(MobileJobNoteDetailComponent, {
      data: {
        note: jobApplication?.notes,
      },
      maxWidth: '98vw',
      maxHeight: '98vw',
    });
  }

  public async requestTravelCosts(jobApplication: JobApplication): Promise<void> {
    if (this.isBusyButton === 'requestTravelCosts') {
      return;
    }

    this.isBusyButton = 'requestTravelCosts';
    this.isBusyId = jobApplication.id;

    const translation = await this.translate
      .get('If you immediately want to ask the travel cost from all booked talents, please click ‘complete job’.')
      .toPromise();
    if (confirm(translation)) {
      await this.jobApplicationService.requestTravelCosts(jobApplication.id).toPromise();
      window.location.reload();
    }

    this.isBusyButton = '';
  }

  public async requestToBookAndCreatePayment(jobApplication: JobApplication, job: Job): Promise<void> {
    if (this.isBusyButton === 'requestToBookAndCreatePayemt') {
      return;
    }

    this.isBusyButton = 'requestToBookAndCreatePayment';
    this.isBusyId = jobApplication.id;
    const dialogRef = this.dialog.open(RequestToBookModalComponent, {
      data: {
        user: this.applicant.user,
        jobApplication,
        job,
      },
    });
    await dialogRef.afterClosed().toPromise();
    this.isBusyButton = '';
  }

  public async requestToBook(jobApplication: JobApplication, job: Job): Promise<void> {
    if (this.isBusyButton === 'requestToBook') {
      return;
    }

    this.isBusyButton = 'requestToBook';
    this.isBusyId = jobApplication.id;
    const dialogRef = this.dialog.open(RequestToBookModalComponent, {
      data: {
        user: this.applicant.user,
        jobApplication,
        job,
      },
    });
    await dialogRef.afterClosed().toPromise();
    this.isBusyButton = '';
  }

  public createPayment(jobApplication: JobApplication): void {
    if (this.isBusyButton === 'createPayment') {
      return;
    }

    this.isBusyButton = 'createPayment';
    this.isBusyId = jobApplication.id;
    const returnUrl = `${environment.baseUrl}/account/jobs/status/open`;
    this.store.dispatch(new CreatePayment(jobApplication.user.id, jobApplication.id, returnUrl));
  }

  public async releasePayment(jobApplication: JobApplication, days: number): Promise<void> {
    if (this.isBusyButton === 'releasePayment') {
      return;
    }

    this.isBusyButton = 'releasePayment';
    this.isBusyId = jobApplication.id;
    const translation = await this.translate.get('Are you sure you want to release the payment?').toPromise();
    if (confirm(translation)) {
      this.store.dispatch(new UpdateEscrow(jobApplication.user.id, jobApplication.payment.id, days));
    }
    this.isBusyButton = '';
  }

  public async requestRefundPayment(jobApplication: JobApplication): Promise<void> {
    if (this.isBusyButton === 'requestRefundPayment') {
      return;
    }

    this.isBusyButton = 'requestRefundPayment';
    this.isBusyId = jobApplication.id;
    const dialogRef = this.dialog.open(RequestRefundJobApplicationComponent, {
      data: {
        jobApplication,
        payment: jobApplication.payment,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();
    if (result === true) {
      this.requestedRefund = true;
    }
    this.isBusyButton = '';
  }

  public async reject(jobApplication: JobApplication): Promise<void> {
    if (this.isBusyButton === 'reject') {
      return;
    }

    this.isBusyButton = 'reject';
    const dialogRef = this.dialog.open(BulkRejectComponent, {
      data: {
        jobId: this.job.id,
        jobApplicationIds: [jobApplication.id],
      },
    });

    await dialogRef.afterClosed().toPromise();

    this.isBusyButton = '';
  }

  public async cancelBooking(jobApplication: JobApplication): Promise<void> {
    const dialogRef = this.dialog.open(CasterCancelBookingComponent, {
      data: { jobApplication },
    });

    await dialogRef.afterClosed().toPromise();
  }

  public async openChatModal(): Promise<void> {
    const senderId = this.job.user.id;
    const recipientId = this.applicant.user.id;
    if (recipientId != null) {
      await this.store.dispatch(new InitiateChat(senderId, recipientId)).toPromise();
    }

    const dialogRef = this.dialog.open(MessagingModalComponent, {
      data: {
        userId: this.job.user.id,
        recipientId: this.applicant.user.id,
      },
    });
    const result = await dialogRef.afterClosed().toPromise();

    if (!result) {
      return;
    }
  }

  public showCreatePaymentButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      [JobApplicationStatus.offered, JobApplicationStatus.accepted].includes(this.applicant.status) &&
      this.applicant.payment?.paymentStatus !== 'reserved' &&
      !this.job.bypassPayment
    );
  }

  public showReleasePaymentButton(): boolean {
    if (!this.applicant || !this.job) {
      return false;
    }

    return (
      !this.requestedRefund &&
      !this.applicant.payment?.refundRequested &&
      this.applicant.status === JobApplicationStatus.open &&
      this.applicant.payment?.paymentStatus === 'reserved'
    );
  }

  public showRequestRefundButton(): boolean {
    if (!this.applicant || !this.job) {
      return false;
    }

    return (
      !this.requestedRefund &&
      !this.applicant.payment?.refundRequested &&
      [
        JobApplicationStatus.offered,
        JobApplicationStatus.open,
        JobApplicationStatus.cancelbooking,
        JobApplicationStatus.rejectbooking,
        JobApplicationStatus.castercancelbooking,
      ].includes(this.applicant.status) &&
      this.applicant.payment?.paymentStatus === 'reserved'
    );
  }

  public showSendTravelCostsRequest(): boolean {
    if (!this.applicant || !this.job) {
      return false;
    }

    return (
      !this.applicant.tcEmailTalentSent &&
      [JobApplicationStatus.confirmed, JobApplicationStatus.completed].includes(this.applicant.status) &&
      !this.job.completed &&
      !(moment(this.job.lastDate.end) < moment()) &&
      this.job.travelingCost > 0
    );
  }

  public showRequestedTravelCosts(): boolean {
    if (!this.applicant || !this.job) {
      return false;
    }

    return (
      this.applicant.tcEmailTalentSent &&
      ![
        JobApplicationStatus.travelCostPending,
        JobApplicationStatus.travelCostApproved,
        JobApplicationStatus.travelCostRejected,
      ].includes(this.applicant.status)
    );
  }

  public showRequestToBookWithPaymentButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      [JobApplicationStatus.applied, JobApplicationStatus.rejected].includes(this.applicant.status) &&
      !this.job.bypassPayment
    );
  }

  public showRequestToBookBypassPaymentButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      [JobApplicationStatus.applied, JobApplicationStatus.rejected].includes(this.applicant.status) &&
      this.job.bypassPayment
    );
  }

  public showRejectApplicantButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return this.applicant.status === JobApplicationStatus.applied;
  }

  public showCancelBookingButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return [JobApplicationStatus.offered, JobApplicationStatus.confirmed, JobApplicationStatus.open].includes(
      this.applicant.status,
    );
  }

  public showCancelRequestBookingButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return [JobApplicationStatus.accepted, JobApplicationStatus.requesttobook].includes(this.applicant.status);
  }

  public showVideoRequestedInfo(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return this.applicant.casterRequestedVideo !== null && this.applicant.talentVideoId === null;
  }

  public showVideoButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return this.applicant.talentVideoId !== null && this.job.requestTalentVideo;
  }

  public showRequestVideoButton(): boolean {
    if (!this.applicant || !this.job || (this.job && this.job.completed)) {
      return false;
    }

    return (
      this.job.requestTalentVideo === true &&
      this.applicant.casterRequestedVideo === null &&
      this.applicant.talentVideoId === null &&
      !this.showVideoRequestedInfo()
    );
  }

  public getBodyContentClass(): string {
    if (!this.applicant || !this.job) {
      return 'body-content-3btn';
    }

    const totalButton =
      [
        this.showCreatePaymentButton(),
        this.showReleasePaymentButton(),
        this.showRequestRefundButton(),
        this.showRequestToBookWithPaymentButton(),
        this.showRequestToBookBypassPaymentButton(),
        this.showRejectApplicantButton(),
        this.showCancelBookingButton(),
        this.showCancelRequestBookingButton(),
      ].filter((result: boolean) => result).length + 1;

    if (totalButton === 3) {
      return 'body-content-3btn';
    } else if (totalButton === 2) {
      return 'body-content-2btn';
    } else if (totalButton === 1) {
      return 'body-content-1btn';
    }
  }

  public travelCosts(applicant: JobApplication): void {
    this.store.dispatch(new Navigate(['account/jobs/travel-expenses', applicant.id]));
  }

  public get nativeLanguage(): Language {
    return this.applicant.user.languages.filter((userLanguage) => userLanguage.native)?.[0];
  }
}
