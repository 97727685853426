import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import {
  FetchJobApplication,
  JobsState,
  SaveJobApplicationTravelExpenses,
  UserState,
  UserStateModel,
} from '@core/states';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountBankNumber, JobApplication, LocalFile, Media, TravelExpense, User } from '@core/models';
import { filter, take } from 'rxjs/operators';
import { v4 } from 'uuid';
import { IUser, JobApplicationStatus, TravelExpenseType } from '@core/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { FileService } from '@src/ui/generic/images/services/file.service';
import { TranslateService } from '@ngx-translate/core';
import { JobInvoiceService } from '@core/services/job-invoice';
import { AccountNumberModalComponent } from '@src/visitor/modals/components/account-number-modal/account-number-modal.component';
import { MobileSuccessModalComponent } from '@shared/mobile-components/mobile-success-modal/mobile-success-modal.component';
import { MobileClaimTravelExpensesIncorrectFeeComponent } from '../mobile-claim-travel-expenses-incorrect-fee/mobile-claim-travel-expenses-incorrect-fee.component';
import { JobApplicationsService } from '@core/services';

const numberOnlyValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value: string = control.value;

    if (value === null || value === '') {
      return null; // Don't validate empty values to allow optional controls
    }

    // Check if the string contains only digits
    const valid = /^\d+$/.test(value);

    return valid ? null : { numberOnly: true };
  };
};

@Component({
  selector: 'app-mobile-claim-travel-expenses',
  templateUrl: './mobile-claim-travel-expenses.component.html',
  styleUrls: ['./mobile-claim-travel-expenses.component.scss'],
})
export class MobileClaimTravelExpensesComponent implements OnInit {
  @Select(JobsState.jobApplication)
  public jobApplication$: Observable<JobApplication>;

  @Select(UserState)
  public userState$: Observable<UserStateModel>;

  @Select(UserState.user)
  public user$: Observable<IUser>;

  @ViewChild('picture')
  public pictureEl!: ElementRef;

  @ViewChild('incorrectFeeModal') private incorrectFeeModal: MobileClaimTravelExpensesIncorrectFeeComponent;
  @ViewChild('submitConfirmationModal') private submitConfirmationModal: MobileSuccessModalComponent;
  @ViewChild('nextStepConfirmationModal') private nextStepConfirmationModal: MobileSuccessModalComponent;
  @ViewChild('zeroTravelCost') private zeroTravelCost: MobileSuccessModalComponent;
  @ViewChild('accountNumberModal') private accountNumberModalForm: AccountNumberModalComponent;
  @ViewChild('successSubmitModal') private successSubmitModal: MobileSuccessModalComponent;
  @ViewChild('zeroTravelCostConfirmation') private zeroTravelCostConfirmation: MobileSuccessModalComponent;
  @ViewChild('submitZeroTravelCostConfirmation') private submitZeroTravelCostConfirmation: MobileSuccessModalComponent;
  @ViewChild('successSubmitAlternateModal') private successSubmitAlternateModal: MobileSuccessModalComponent;
  @ViewChild('formContainer') private formContainer!: ElementRef;

  public form: FormGroup = new FormGroup({
    id: new FormControl(null, [Validators.required]),
    ownNumber: new FormControl(null),
    withoutTc: new FormControl(false),
    travelExpenses: new FormArray([], [Validators.required]),
    travelReceipts: new FormArray([]),
    accountNumber: new FormGroup({
      bankNumber: new FormControl(null, [Validators.required]),
      accountName: new FormControl(null),
      coc: new FormControl(null),
      vat: new FormControl(null),
      kor: new FormControl(false),
    }),
  });

  public isBusy = false;
  public isBusyUpload = false;
  public noTc: boolean;
  public step = 1;
  public maxInvoiceTC = 0;
  public maxInvoicePC = 0;
  public tcAmount = 0;
  public IDs: Array<string> = [];

  protected readonly JobApplicationStatus = JobApplicationStatus;

  protected readonly FormGroup = FormGroup;

  private jobApplication: JobApplication;
  private userDetail: User;
  private jobApplicationId = '';

  public constructor(
    private store: Store,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fileService: FileService,
    private translate: TranslateService,
    private jobInvoiceService: JobInvoiceService,
    private jobApplicationService: JobApplicationsService,
  ) {
    this.jobApplicationId = this.route.snapshot.paramMap.get('id');
    this.store.dispatch(new FetchJobApplication(this.jobApplicationId));
  }

  public async ngOnInit(): Promise<void> {
    this.jobApplication = await this.jobApplication$
      .pipe(
        filter((j: JobApplication) => !!j),
        take(1),
      )
      .toPromise();

    this.userDetail = await this.user$
      .pipe(
        filter((j: User) => !!j),
        take(1),
      )
      .toPromise();

    if (window.innerWidth <= 500) {
      // const jobApplication = await this.jobApplication$
      //   .pipe(
      //     filter((j: JobApplication) => !!j),
      //     take(1),
      //   )
      //   .toPromise();
      // this.routers
      //   .navigate([`/account/job-applications/${jobApplication.status}`], {
      //     queryParams: {
      //       action: 'talentTravelCost',
      //       applicantID: jobApplication.id,
      //       noTc: this.noTc,
      //     },
      //   })
      //   .then(() => {
      //     window.location.reload();
      //   });
      // return;
    }

    if (this.noTc || (!this.jobApplication?.jobInvoiceItem?.tc && !this.jobApplication?.jobInvoiceItem?.pc)) {
      this.step = 2;
      this.form.patchValue({ withoutTc: true });
    }

    if (
      this.jobApplication?.jobInvoiceItem?.status === 'review' ||
      this.jobApplication?.jobInvoiceItem?.status === 'pending' ||
      this.jobApplication?.jobInvoiceItem?.status === 'reject' ||
      this.jobApplication?.jobInvoiceItem?.status === 'complete'
    ) {
      this.step = 2;
      this.form.controls['ownNumber'].disable();
    }

    if (this.userDetail?.accountNumber) {
      this.form.setControl(
        'accountNumber',
        new FormGroup({
          bankNumber: new FormControl(this.userDetail?.accountNumber?.accountNumber, [Validators.required]),
          accountName: new FormControl(this.userDetail?.accountNumber?.accountName),
          coc: new FormControl(this.userDetail?.accountNumber?.coc || null, [numberOnlyValidator()]),
          vat: new FormControl(this.userDetail?.accountNumber?.vat),
          kor: new FormControl(this.userDetail?.accountNumber?.kor),
        }),
      );
    }

    this.form.patchValue(this.jobApplication);
    this.form.patchValue({ ownNumber: this.jobApplication?.jobInvoiceItem?.ownNumber });
    this.jobApplication.travelReceipts.forEach((receipt: Media) =>
      this.travelReceipts.push(
        new FormGroup({
          id: new FormControl(receipt.id, [Validators.required]),
          name: new FormControl(receipt.id, [Validators.required]),
          url: new FormControl(receipt.url, [Validators.required]),
        }),
      ),
    );

    const typeKM = this.jobApplication.travelExpenses.filter((tc) => tc.type === TravelExpenseType.kilometers);
    const typePC = this.jobApplication.travelExpenses.filter((tc) => tc.type === TravelExpenseType.parking);
    const typeOther = this.jobApplication.travelExpenses.filter((tc) => tc.type === TravelExpenseType.other);
    if (typeKM.length > 0) {
      typeKM.forEach((te: TravelExpense, index: number) => {
        if (index === 0) {
          this.IDs.push(te.id);
        }
        this.travelExpenses.push(
          new FormGroup({
            id: new FormControl(te.id, [Validators.required]),
            type: new FormControl(te.type, [Validators.required]),
            description: new FormControl(te.description),
            distance: new FormControl(te.distance),
            time: new FormControl(te.time),
            price: new FormControl(te.price, [Validators.required]),
          }),
        );
      });
    } else {
      this.addTravelExpense(TravelExpenseType.kilometers);
    }

    if (typeOther.length > 0) {
      typeOther.forEach((te: TravelExpense, index: number) => {
        if (index === 0) {
          this.IDs.push(te.id);
        }
        this.travelExpenses.push(
          new FormGroup({
            id: new FormControl(te.id, [Validators.required]),
            type: new FormControl(te.type, [Validators.required]),
            description: new FormControl(te.description),
            distance: new FormControl(te.distance),
            time: new FormControl(te.time),
            price: new FormControl(te.price, [Validators.required]),
          }),
        );
      });
    } else {
      this.addTravelExpense(TravelExpenseType.other);
    }

    if (typePC.length > 0) {
      typePC.forEach((te: TravelExpense, index: number) => {
        if (index === 0) {
          this.IDs.push(te.id);
        }
        this.travelExpenses.push(
          new FormGroup({
            id: new FormControl(te.id, [Validators.required]),
            type: new FormControl(te.type, [Validators.required]),
            description: new FormControl(te.description),
            distance: new FormControl(te.distance),
            time: new FormControl(te.time),
            price: new FormControl(te.price, [Validators.required]),
          }),
        );
      });
    } else {
      this.addTravelExpense(TravelExpenseType.parking);
    }

    if (this.route.snapshot.queryParamMap.get('action') === 'notc') {
      this.noTc = true;
      this.step = 2;
      this.form.patchValue({
        withoutTc: true,
      });
    }
  }

  public get travelExpenses(): FormArray {
    return this.form.get('travelExpenses') as FormArray;
  }

  public get travelReceipts(): FormArray {
    return this.form.get('travelReceipts') as FormArray;
  }

  public addTravelExpense(type: TravelExpenseType): void {
    const id = v4();
    this.IDs.push(id);
    this.travelExpenses.push(
      new FormGroup({
        id: new FormControl(id, [Validators.required]),
        type: new FormControl(type, [Validators.required]),
        description: new FormControl(null),
        distance: new FormControl(null),
        time: new FormControl(null),
        price: new FormControl(null),
      }),
    );
  }

  public addReceipt(): void {
    this.travelReceipts.push(
      new FormGroup({
        id: new FormControl(v4(), [Validators.required]),
        name: new FormControl(null, [Validators.required]),
        url: new FormControl(null, [
          (control: FormControl) => {
            if (control.value != null) {
              if (
                !(control.value instanceof File) ||
                !['image/png', 'image/x-png', 'image/gif', 'image/jpeg'].includes((control.value as File).type)
              ) {
                return { invalid: true };
              }
            }
            return null;
          },
        ]),
      }),
    );
  }

  public showButtonDelete(id: string): boolean {
    if (this.IDs.find((res) => res === id)) {
      return false;
    }
    return true;
  }

  public showAddButton(type: TravelExpenseType, index: number) {
    if (!this.jobApplication?.jobInvoiceItem?.tc && !this.jobApplication?.jobInvoiceItem?.pc) {
      return;
    }

    const kilometersFormLength = this.form?.value?.travelExpenses?.filter((obj) => {
      return obj.type === TravelExpenseType.kilometers;
    }).length;
    const othersFormLength = this.form?.value?.travelExpenses?.filter((obj) => {
      return obj.type === TravelExpenseType.other;
    }).length;
    switch (type) {
      case TravelExpenseType.kilometers:
        return kilometersFormLength === index;
        break;
      case TravelExpenseType.other:
        return othersFormLength === index - kilometersFormLength;
        break;

      default:
        break;
    }
  }

  public removeTravelExpense(id: string): void {
    const index: number = this.form.value.travelExpenses.findIndex((a): boolean => a.id === id);
    this.travelExpenses.removeAt(index);
  }

  public copyTravelExpense(form: FormGroup): void {
    const index: number = this.form.value.travelExpenses.findIndex((a): boolean => a.id === form.get('id')?.value);
    this.travelExpenses.insert(
      index + 1,
      new FormGroup({
        id: new FormControl(v4(), [Validators.required]),
        type: new FormControl(form.get('type')?.value, [Validators.required]),
        description: new FormControl(form.get('description')?.value),
        distance: new FormControl(form.get('distance')?.value),
        time: new FormControl(form.get('time')?.value),
        price: new FormControl(form.get('price')?.value, [Validators.required]),
      }),
    );
  }

  public removeReceipt(index: number): void {
    this.travelReceipts.removeAt(index);
  }

  public async nextStep(): Promise<void> {
    if (
      (this.jobApplication?.jobInvoiceItem?.tc || this.jobApplication?.jobInvoiceItem?.pc) &&
      this.travelExpenses.controls.filter((c) => c.get('price').value !== null).length < 1 &&
      !this.noTc
    ) {
      const translation = await this.translate
        .get('Please fill in your travel cost or select no travel cost.')
        .toPromise();
      confirm(translation);
      return;
    }

    let hasErrors = false;
    // Validate all controls except 'accountNumber'
    Object.keys(this.form.controls).forEach((key) => {
      if (key !== 'accountNumber') {
        const control = this.form.get(key);
        if (control && control.invalid) {
          hasErrors = true;
        }
      }
    });
    if (hasErrors) {
      return;
    }

    this.nextStepConfirmationModal.openDialog(false);
  }

  public async submitConfirmation(): Promise<void> {
    if (this.form.controls['accountNumber'].invalid) {
      const translation = await this.translate.get('Please fill all field on payment information').toPromise();
      confirm(translation);
      return;
    }

    this.submitConfirmationModal.openDialog(false);
  }

  public async cancelSubmitConfirmation(): Promise<void> {
    this.submitConfirmationModal.close();
  }

  public async submitZeroTravelCostConfirmationModal(openDialog: boolean): Promise<void> {
    if (openDialog) {
      this.submitZeroTravelCostConfirmation.openDialog(false);
    } else {
      this.submitZeroTravelCostConfirmation.close();
    }
  }

  public async submit(status?: string): Promise<void> {
    this.isBusy = true;
    this.travelExpenses?.controls
      .filter((c) => c.get('price').value === null)
      .map((c) => {
        this.removeTravelExpense(c.get('id')?.value);
      });

    const jobApplication: JobApplication = this.form.value;
    const bank: AccountBankNumber = {
      accountNumber: this.form.get('accountNumber.bankNumber')?.value,
      accountName: this.form.get('accountNumber.accountName')?.value,
      coc: this.form.get('accountNumber.coc')?.value,
      vat: this.form.get('accountNumber.vat')?.value,
      kor: this.form.get('accountNumber.kor')?.value,
    };

    this.store
      .dispatch(
        new SaveJobApplicationTravelExpenses(
          jobApplication.id,
          jobApplication.travelExpenses,
          jobApplication.travelReceipts?.map((item: Media): Partial<Media> => ({ id: item.id })),
          bank,
          status !== undefined ? 'pending' : null,
          this.form.get('ownNumber')?.value,
          this.noTc,
        ),
      )
      .toPromise()
      .then(() => {
        // this.isBusy = false;
        this.jobInvoiceService.sync(jobApplication.id);
        window.location.reload();
      });
  }

  public reloadPage(): void {
    window.location.reload();
  }

  public async uploadReceipt(file: File): Promise<void> {
    const id = v4();

    const receipt: File = file[0];

    if (!receipt) {
      return;
    }

    this.isBusyUpload = true;

    const fileResult: LocalFile = await this.fileService
      .uploadAndResize(
        {
          id,
          name: receipt.name,
          type: receipt.type,
          size: receipt.size,
          target: {
            type: 'receipt',
            payload: id,
          },
          data: await this.fileService.readFile(receipt),
        },
        1000,
      )
      .toPromise();

    this.isBusyUpload = false;

    const imgBase64 = await this.fileService.readFile(receipt);
    this.travelReceipts.push(
      new FormGroup({
        id: new FormControl(id, [Validators.required]),
        name: new FormControl(fileResult.name, [Validators.required]),
        url: new FormControl(imgBase64 ? imgBase64 : null, [Validators.required]),
      }),
    );
  }

  public chooseImage(): void {
    if (this.isBusyUpload) {
      return;
    }
    this.pictureEl.nativeElement.click();
  }

  public backStep(): void {
    this.form.patchValue({
      withoutTc: false,
    });
    this.noTc = false;
    this.step = 1;
  }

  public async confirmNoTravelcost(showAgreement: boolean): Promise<void> {
    this.cancelNoTravelCost();
    this.zeroTravelCost.openDialog(false);
    this.noTc = true;
    this.form.patchValue({
      withoutTc: true,
    });
    if (showAgreement) {
      this.confirmAgreement(false);
    }
  }

  public async cancelNoTravelCost(): Promise<void> {
    this.zeroTravelCostConfirmation.close();
  }

  public async openNoTracelcostAgreement(): Promise<void> {
    this.zeroTravelCostConfirmation.openDialog(false);
  }

  public incorrectFee(): void {
    const jobApplicationId: string = this.route.snapshot.paramMap.get('id');
    this.incorrectFeeModal.openDialog(jobApplicationId);
  }

  public getComission(total: number, comission: number) {
    return Number(total * comission) / 100;
  }

  public getTravelCost() {
    let totalTc = 0;

    const travelCost = this.travelExpenses.controls.filter((c) => c.get('type')?.value !== TravelExpenseType.parking);

    travelCost.forEach((c) => {
      totalTc += c.get('price')?.value;
    });

    if (this.form.get('withoutTc')?.value || this.noTc) {
      return 'No Travel Cost';
    } else if (totalTc > 0) {
      return `€${totalTc}`;
    }
    return 'Fill in below';
  }

  public getParkingCost() {
    let totalPc = 0;

    const parkingCost = this.travelExpenses.controls.filter((c) => c.get('type')?.value === TravelExpenseType.parking);

    parkingCost.forEach((c) => {
      totalPc += c.get('price')?.value;
    });

    if (this.form.get('withoutTc')?.value) {
      return 'No Travel Cost';
    } else if (totalPc > 0) {
      return `€${totalPc}`;
    }
    return 'Fill in below';
  }

  public totalFeeExcl(total: number, comission: number) {
    let totalTc = 0;

    if (this.jobApplication?.status === JobApplicationStatus.travelCostApproved) {
      totalTc = this.jobApplication?.travelExpenses?.reduce((n, { price }) => n + price, 0);
    }

    return Number(total - (total * comission) / 100 + totalTc).toFixed(2);
  }

  public totalVAT(total: number, comission: number) {
    let totalTc = 0;

    if (this.jobApplication?.status === JobApplicationStatus.travelCostApproved) {
      totalTc = this.jobApplication?.travelExpenses?.reduce((n, { price }) => n + price, 0);
    }

    total = total - (total * comission) / 100 + totalTc;
    return Number((total * 21) / 100).toFixed(2);
  }

  public totalFeeIncl(total: number, comission: number) {
    let totalTc = 0;

    if (this.jobApplication?.status === JobApplicationStatus.travelCostApproved) {
      totalTc = this.jobApplication?.travelExpenses?.reduce((n, { price }) => n + price, 0);
    }

    total = total - (total * comission) / 100 + totalTc;
    return Number(total + (total * 21) / 100).toFixed(2);
  }

  public showFormAccountNumber() {
    const form = this.form.controls['accountNumber'];
    this.accountNumberModalForm.openDialog(form);
  }

  public saveAccountNumber(data: any) {
    this.accountNumberModalForm.close();
    this.form.setControl('accountNumber', data);
  }

  public confirmAgreement(closeModal: boolean = true): void {
    this.smoothScroll(this.formContainer.nativeElement, 0, 300);
    this.formContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    if (closeModal) {
      this.zeroTravelCost.close();
      this.nextStepConfirmationModal.close();
    }
    this.step = 2;
  }

  private smoothScroll(element: HTMLElement, to: number, duration: number) {
    const start = element.scrollTop;
    const change = to - start;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      if (elapsedTime > duration) {
        element.scrollTop = to;
        return;
      }

      element.scrollTop = this.easeInOutCubic(elapsedTime, start, change, duration);
      requestAnimationFrame(animateScroll);
    };

    requestAnimationFrame(animateScroll);
  }

  private easeInOutCubic(t: number, b: number, c: number, d: number): number {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t * t + b;
    t -= 2;
    return (c / 2) * (t * t * t + 2) + b;
  }
}
