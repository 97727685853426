<div *ngIf="talent" class="d-flex flex-column w-100 h-100">
  <div [ngStyle]="{ 'height.px': imageSize?.height }" class="position-relative">
    <app-image
      [image]="talent.firstPhotoAlbum?.media[0]?.thumbUrl"
      [ngStyle]="{ 'width.px': imageSize?.width, 'height.px': imageSize?.height }"
      class="cover d-block rounded-sm initial"></app-image>

    <app-gradient class="top-bar rounded-sm" gradientDirection="vertical" gradientName="black-transparent">
      <app-icon
        *ngIf="talent.user.isPro"
        [useOriginal]="true"
        class="icon"
        icon="/assets/img/talent-pro.svg"></app-icon>
      <app-add-to-deck-button
        *ngIf="showAddToDeck"
        [userProfile]="talent"
        class="icon ml-auto"></app-add-to-deck-button>
    </app-gradient>

    <app-gradient
      [gradientName]="hover ? 'purple-bright-transparent' : 'black-transparent'"
      class="bottom-bar rounded-sm d-flex flex-column justify-content-end"
      gradientDirection="vertical-reverse">
      <div class="d-flex flex-row mb-1">
        <span translate>from</span>
        <app-money [amount]="talent.rate" class="h5 ml-1"></app-money>
        <span class="ml-1">p/d</span>
      </div>

      <ng-container *ngIf="talent?.user?.reviews?.length > 0">
        <app-rating [average]="talent?.averageReview" [count]="talent.totalReviews"></app-rating>
      </ng-container>
    </app-gradient>
  </div>
  <div class="footer d-flex flex-fill align-items-center mt-auto">
    <img
      *ngIf="talent.user.photo; else noPhoto"
      [src]="talent.user.photo.url"
      class="profile-image rounded-circle mr-3"
      loading="lazy" />
    <ng-template #noPhoto>
      <div class="profile-image bg-gray rounded-circle mr-3"></div>
    </ng-template>
    <div [ngStyle]="{ 'width.px': tileSize.width - 56 }" class="flex-column details">
      <div class="d-flex flex-row">
        <span>{{ talent.user.profileName }}</span>
        <app-icon *ngIf="talent.user?.hasBadge" icon="/assets/img/memberships-badge.svg" class="size-5 ml-1"></app-icon>
      </div>
      <div class="text-gray">{{ talent.user.city }}, {{ talent.user.country.isoCode }}</div>
      <div class="text-gray">{{ talent.category.translateKeySingular | translate }}</div>
    </div>
  </div>
</div>
