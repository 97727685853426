import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IMedia, MediaType } from '@core/interfaces';
import { User, UserProfile } from '@core/models';
import { RouterHelperService } from '@core/services';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { MediaItemModalComponent } from '@src/visitor/modals/components/media-item-modal/media-item-modal.component';
import { first } from 'rxjs/operators';
import { MobileTalentPhotoalbumPreviewComponent } from '../mobile-talent-photoalbum-preview/mobile-talent-photoalbum-preview.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
interface iBreadcumbTabList {
  key: string;
  label: string;
}

@Component({
  selector: 'app-mobile-talent-photoalbum-overview',
  templateUrl: './mobile-talent-photoalbum-overview.component.html',
  styleUrls: ['./mobile-talent-photoalbum-overview.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('300ms ease-out', style({ height: 300, opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: 300, opacity: 1 }),
        animate('300ms ease-in', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class MobileTalentPhotoalbumOverviewComponent implements OnChanges, AfterContentChecked {
  @ViewChildren('photoContainer') photoContainer: QueryList<ElementRef>;

  @Output()
  public breadcrumbChange = new EventEmitter();

  @Output()
  public openEditProfile = new EventEmitter();

  @Input()
  public talent: User;

  @Input()
  public profiles: UserProfile[];

  @Input()
  public profile: UserProfile;

  @Input()
  public indexActiveTab = 0;

  public activeBreadcrumbTab: iBreadcumbTabList;

  public items = [];
  public breadcrumbTabList: iBreadcumbTabList[] = [
    {
      key: 'photo',
      label: 'Photos',
    },
    // temporary hide until the feature is ready
    // {
    //   key: 'selftape',
    //   label: 'Selftape',
    // },
    {
      key: 'video',
      label: 'Videos',
    },
    {
      key: 'about',
      label: 'About',
    },
  ];
  public profilePic = '';
  public photoalbumContainerHeight = 500;
  private hasProcessed = false;

  constructor(private dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if ('profile' in changes) {
      this.onSelectBreadcrumb(this.breadcrumbTabList[this.indexActiveTab]);
    }
  }

  public async onSelectBreadcrumb(data: iBreadcumbTabList): Promise<void> {
    this.breadcrumbChange.emit(data.key);
    this.activeBreadcrumbTab = data;
    const filteredMedia = this.profile.albums.find(
      (value) => value.albumType === (data.key === 'about' ? MediaType.photo : data.key),
    );
    if (filteredMedia?.media?.length >= 1) {
      this.items = filteredMedia.media;
      this.photoalbumContainerHeight = 500 * (this.items.length / 2);

      if (data.key === 'photo' || data.key === 'about') {
        this.profilePic = this.items[0].thumbUrl;
      }

      if (data.key === 'photo') {
        this.getMasonryLayoutHeightContainer();
      }
    }
  }

  async ngAfterContentChecked(): Promise<void> {
    if (!this.hasProcessed) {
      this.hasProcessed = true;
      if (this.activeBreadcrumbTab.key === 'photo') {
        this.getMasonryLayoutHeightContainer();
      }

      if (this.activeBreadcrumbTab.key === 'about') {
        setTimeout(() => {
          this.openEditProfile.emit('');

          // removing param on url immediatly
          const queryParams: Params = {};
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
          });
        }, 500);
      }
    }
  }

  public getMasonryLayoutHeightContainer() {
    // Set the flag to true to indicate that the code has been processed
    setTimeout(async () => {
      const leftImageList: number[] = [];
      const rightImageList: number[] = [];

      // Create an array of promises for each child element's asynchronous task
      const promises = this.photoContainer.map(async (child, index) => {
        const childHeight = child.nativeElement.offsetHeight;
        if (index % 2 === 0) {
          leftImageList.push(childHeight + 12);
        } else {
          rightImageList.push(childHeight + 12);
        }
      });

      // Wait for all promises to be resolved
      await Promise.all(promises);
      if (!leftImageList.some((value) => value < 50) && !rightImageList.some((value) => value < 50)) {
        const leftImageTotalHeight = leftImageList.reduce(
          (accumulator, currentNumber) => accumulator + currentNumber,
          0,
        );
        const rightImageTotalHeight = rightImageList.reduce(
          (accumulator, currentNumber) => accumulator + currentNumber,
          0,
        );

        const containerHeight: number = Math.max(leftImageTotalHeight, rightImageTotalHeight);
        this.photoalbumContainerHeight = containerHeight;
      }
    }, 2000);
  }

  public moveItemUp(index: number): void {
    if (index > 0 && index < this.items.length) {
      this.items = this.swapItems(index, index - 1);
    }
  }

  public moveItemDown(index: number): void {
    if (index >= 0 && index < this.items.length - 1) {
      this.items = this.swapItems(index, index + 1);
    }
  }

  public openMedia(media: IMedia, mediaPosition: number): void {
    if (media.mediaType === 'photo') {
      this.dialog.open(MobileTalentPhotoalbumPreviewComponent, {
        data: {
          mediaPosition,
          mediaList: this.items,
        },
      });
    } else {
      this.dialog.open(MediaItemModalComponent, {
        data: {
          width: 400,
          height: 250,
          media,
        },
        maxWidth: '98vw',
        maxHeight: '98vw',
      });
    }
  }

  public hasAlbumType(key: any): boolean {
    return this.profile.albums.some((a) => a.albumType === key);
  }

  private swapItems(index1: number, index2: number): any[] {
    const updatedItems = this.items.slice(); // create a copy of the array
    const temp = updatedItems[index1];
    updatedItems[index1] = updatedItems[index2];
    updatedItems[index2] = temp;
    return updatedItems;
  }
}
